import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { API_BASE_URL, API_KEY } from '../../../config/config';
import Breadcrumb from '../../common/Breadcrumb';
import AnalyticsService from '../../../services/analytics';
import Loader from '../../common/Loader';
import { v4 as uuidv4 } from 'uuid';

import './photoalbumlist.scss';
import Layout from '../../Layout/Layout';

export default class PhotoAlbumList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    AnalyticsService.sendPageView(window.location.pathname);
    this.getItems();
  }

  componentWillReceiveProps(nextProps) {
    this.getItems();
  }

  getItems() {
    fetch(`${API_BASE_URL}/photo-album/list`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState({
            isLoaded: true,
            items: json,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  render() {
    const { error, isLoaded, items } = this.state;

    if (error) {
      return <div>Hiba történt!</div>;
    } if (!isLoaded) {
      return (
        <Layout type="slim" cssClassName="photoalbum-container">
          <Breadcrumb />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="album-header">Fotóalbumok</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-4 loader-container">
                <Loader />
              </div>
              <div className="col-sm-12 col-lg-4 loader-container">
                <Loader />
              </div>
              <div className="col-sm-12 col-lg-4 loader-container">
                <Loader />
              </div>
              <div className="col-sm-12 col-lg-4 loader-container">
                <Loader />
              </div>
              <div className="col-sm-12 col-lg-4 loader-container">
                <Loader />
              </div>
              <div className="col-sm-12 col-lg-4 loader-container">
                <Loader />
              </div>
            </div>
          </div>
        </Layout>
      );
    }
    return (
      <Layout type="slim" cssClassName="photoalbum-container">
        <Breadcrumb />

        <section className="main-content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="album-header">Fotóalbumok</h3>
              </div>
            </div>
            <div className="row">
              {items.map((item) => (
                <div className="col-sm-12 col-lg-4 album-item" key={item.id}>
                  <Link to={`/fotoalbum/${item.id}`}>
                    <div>
                      <img src={`${item.photo}`} alt="" />
                    </div>
                    <div>
                      <p>{item.title}</p>
                      <p>{moment(item.created_at).format('YYYY.MM.DD')}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
