import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumb from '../../common/Breadcrumb';
import AnalyticsService from '../../../services/analytics';
import { API_BASE_URL, API_KEY } from '../../../config/config';
import Loader from '../../common/Loader';
import TwoSideCard from './TwoSideCard';
import OneSideCard from './OneSideCard';
import _ from 'lodash';
import Layout from '../../Layout/Layout';
import { v4 as uuidv4 } from 'uuid';
import Staff from '../Staff/Staff';

import './rosters.scss';

function Roster(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [rosterGoalkeepers, setRosterGoalkeepers] = useState([]);
  const [rosterDefenders, setRosterDefenders] = useState([]);
  const [rosterMidfielders, setRosterMidfielders] = useState([]);
  const [rosterAttackers, setRosterAttackers] = useState([]);
  const [error, setError] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    AnalyticsService.sendPageView(window.location.pathname);

    const squadId = getSquadId(id);

    fetch(`${API_BASE_URL}/roster/list?squad=${squadId}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4(),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong');
      })
      .then(
        (json) => {
          setRosterGoalkeepers(
            _.orderBy(_.filter(json, { position_id: 1 }), ['squad_number'], ['asc'])
          );
          setRosterDefenders(
            _.orderBy(_.filter(json, { position_id: 2 }), ['squad_number'], ['asc'])
          );
          setRosterMidfielders(
            _.orderBy(_.filter(json, { position_id: 3 }), ['squad_number'], ['asc'])
          );
          setRosterAttackers(
            _.orderBy(_.filter(json, { position_id: 4 }), ['squad_number'], ['asc'])
          );
          setIsLoaded(true);
          setError(false);
        },
        (error) => {
          setIsLoaded(true);
          setError(true);
        }
      );
  }, [id]);

  const getSquadId = () => {
    return id === 'elso-csapat' ? 1 : id === 'masodik-csapat' ? 20 : 19;
  };

  if (error) {
    return <div>Hiba történt!</div>;
  }
  if (!isLoaded) {
    return (
      <Layout type="roster" cssClassName="roster-container">
        <Breadcrumb />
        <div className="container">
          <div className="row">
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout type="roster" cssClassName="roster-container">
      <Breadcrumb />

      <section className="main-content">
        <div className="container">
          <h3>Kapusok</h3>
          <div className="player-card-container">
            {rosterGoalkeepers.map((item) => {
              if (item.squad_id === 1) {
                return (
                  <TwoSideCard
                    key={item.id}
                    id={item.id}
                    squad={item.squad_id}
                    name={item.name}
                    photo_name_card={item.photo_name_card}
                  />
                );
              } else if (item.squad_id === 20) {
                return (
                  <OneSideCard
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    photo_name_card={item.photo_name}
                  />
                );
              }
            })}
          </div>
          <h3>Védők</h3>
          <div className="player-card-container">
            {rosterDefenders.map((item) => {
              if (item.squad_id === 1) {
                return (
                  <TwoSideCard
                    key={item.id}
                    id={item.id}
                    squad={item.squad_id}
                    name={item.name}
                    photo_name_card={item.photo_name_card}
                  />
                );
              } else if (item.squad_id === 20) {
                return (
                  <OneSideCard
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    photo_name_card={item.photo_name}
                  />
                );
              }
            })}
          </div>
          <h3>Középpályások</h3>
          <div className="player-card-container">
            {rosterMidfielders.map((item) => {
              if (item.squad_id === 1) {
                return (
                  <TwoSideCard
                    key={item.id}
                    id={item.id}
                    squad={item.squad_id}
                    name={item.name}
                    photo_name_card={item.photo_name_card}
                  />
                );
              } else if (item.squad_id === 20) {
                return (
                  <OneSideCard
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    photo_name_card={item.photo_name}
                  />
                );
              }
            })}
          </div>
          <h3>Támadók</h3>
          <div className="player-card-container">
            {rosterAttackers.map((item) => {
              if (item.squad_id === 1) {
                return (
                  <TwoSideCard
                    key={item.id}
                    id={item.id}
                    squad={item.squad_id}
                    name={item.name}
                    photo_name_card={item.photo_name_card}
                  />
                );
              } else if (item.squad_id === 20) {
                return (
                  <OneSideCard
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    photo_name_card={item.photo_name}
                  />
                );
              }
            })}
          </div>
        </div>

        <Staff squadId={getSquadId(id)} title="Szakmai stáb" />
      </section>
    </Layout>
  );
}

export default Roster;
