import React from 'react';

import { Link } from 'react-router-dom';
import { STATIC_SERVER_URL } from '../../../config/config';

function PhotoAlbumItem(props) {
  return (
    <div className="photo-item">
      <div>
        <Link to={`/galeria/${props.album}/${props.id}`}><img src={`${STATIC_SERVER_URL}/fototar/${props.season}/${props.folder}/${props.item}`} alt="" /></Link>
      </div>
    </div>
  );
}

export default PhotoAlbumItem;
