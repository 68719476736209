/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import Layout from '../../Layout/Layout';
import { API_BASE_URL, API_KEY } from '../../../config/config';
import Breadcrumb from '../../common/Breadcrumb';
import PhotoAlbumItem from './PhotoAlbumItem';
import AnalyticsService from '../../../services/analytics';
import Loader from '../../common/Loader';
import { v4 as uuidv4 } from 'uuid';

import './photoalbumdetails.scss';

function PhotoAlbumDetails(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    AnalyticsService.sendPageView(window.location.pathname);

    fetch(`${API_BASE_URL}/photo-album/details?id=${id}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong');
      })
      .then(
        (json) => {
          setItems(json);
          setIsLoaded(true);
          setError(false);
        },
        (error) => {
          setItems([]);
          setIsLoaded(true);
          setError(true);
        },
      );
  }, [id]);

  function redirectTo404() {
    return <Navigate to="/404" />;
  }

  if (error) {
    return (
      <div>
        {this.redirectTo404()}
      </div>
    );
  } if (!isLoaded) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <Loader />
          </div>
          <div className="col-sm-12 col-lg-6">
            <Loader />
          </div>
        </div>
      </div>
    );
  }
  return (
    <Layout type="slim" cssClassName="photoalbum-container">
      <Breadcrumb />

      <section className="main-content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="album-header">Fotók</h3>
              <p className="album-lead text-center">
                {items.title}
                {' '}
                (
                {items.season}
                ) | Képek száma:
                {' '}
                {items.photolist.length}
                {' '}
                db
              </p>
            </div>
          </div>
          <div>
            <div className="photo-item-container">
              {items.photolist.map((item, index) => (
                <PhotoAlbumItem
                  key={index}
                  id={index}
                  item={item}
                  season={items.season}
                  folder={items.folder}
                  album={items.id}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default PhotoAlbumDetails;
